/** @type {import('./types/harmony-config').IHarmonyConfig} */
const CONFIG = {
    title: '토이플러스',
    description: '어린이들의 천국! 토이 전문점 토이플러스',
    domain: 'toyplus',
    testEmail: 'super@freedgrouptech.com',
    testPassword: 'asdf123',
    pointSystem: '포인트',
    locales: ['ko'],
    defaultLocale: 'ko',
    defaultCurrency: 'KRW',
    backgroundColor: '#FFFFFF',
    primaryFont: 'noto',
    secondaryFont: 'poppins',
    marketplaceTemplate: ['tangible'],
    loginType: ['login'],
    isPwa: false,

    // Keep at bottom of file for pipeline build
    designTemplate: 'designB',
};

module.exports = CONFIG;
